$tablet: 768px;

.head-info-box-single {
    display: none;
    @media screen and (max-width: $tablet) {
        display: block;
        box-shadow:0 10px 20px rgba(0,0,0,.05);
        border:0;
        border-radius:8px;
        background-color:#fff;
    }
}

.head-info-box-multiple {
    display: block;
    @media screen and (max-width: $tablet) {
        display: none;
    }
}

.below-tab{
    @media screen and (max-width: $tablet) {
        display: none;
    }
}