.parentDiv {
  margin-top: 10px;
  position: relative;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid var(--theme-sub-text3-color);
  border-radius: 4px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.childDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
}

.spanHeading {
  font-size: 12px;
  color: #a5a5a5;
}

.spanType {
    font-size: 12px;
    color: #a5a5a5;
}

.spanValue {
  font-size: 14px;
  color: #333333;
}
