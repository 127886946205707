@import "./variables.scss";

html {
  .chakra-button.primary,
  .chakra-button.secondary {
    min-width: 90px;
    outline: none;
    &:focus {
      box-shadow: none;
    }
  }
  .chakra-button.primary {
    background: $primary;
    color: #fff;
  }
  .chakra-button.secondary {
    background: $secondary;
    color: #fff;
  }
  .chakra-tabs__tab {
    &:focus {
      box-shadow: none;
    }
  }
  .chakra-tabs__tab[aria-selected="true"],
  .chakra-tabs__tab[data-selected] {
    color: $primary;
    border-color: currentColor;
  }
}
html {
  body.chakra-ui-light {
    background: #f0f2f7;
  }
}
body {
  .tw-card {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border: 0;
    border-radius: 8px;
    margin-bottom: 20px;
  }
}

.tw-card-2 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border: 0;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
}

.main_body {
  padding: 40px 0;
}

.text-primary {
  color: $primary;
}

.green {
  color: green;
}

.primary:hover[disabled] {
  background: #f70112 !important;
}

.logged-in-btn {
  background: rgba(75, 82, 223, 0.06);
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  img {
    width: 23px;
    height: 19px;
    margin-right: 8px;
  }
  span {
    color: rgba(34, 35, 43, 0.6);
    font-weight: 500;
    font-size: 12px;
  }
}

.title {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:before {
    content: "";
    width: 6px;
    height: 18px;
    display: inline-block;
    background: #f70011;
    border-radius: 1px;
    margin-right: 10px;
  }
}

.side-menu {
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
}

.chakra-ui-dark {
  .logged-in-btn span {
    color: #fff;
  }
}
